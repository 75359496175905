import { FormikControlAtom, MaskHelper } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import {
  RenaveMenuStepsEnum,
  ISaveOutOfStockDealershipRequest,
  SaveOutOfStockDealershipRequest,
  TypeDocumentEnum,
  CheckoutTypeEnum,
} from "../../../models";
import { CheckOutDealershipFormMoleculeValidation } from "./check-out-dealership-form.molecule.validation";

interface IProps {
  setIsSubmitting: (v: boolean) => void;
  goSubmit: boolean;
  creditsToSubmit: () => Promise<boolean>;
  viewMode?: boolean;
}

export const CheckOutDealershipFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { setVehicleCheckoutContext, vehicleCheckoutContext } =
    useRenaveContext();
  const { saveOutOfStock } = useRenave();
  const { handleFormikException, showGenericSuccessModal } =
    useSessionContext();
  const { setMenuCurrentStepContext } = useRenaveContext();

  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const formik = useFormik<ISaveOutOfStockDealershipRequest>({
    initialValues: {
      businessNumber: vehicleCheckoutContext?.buyerDocument,
      email: vehicleCheckoutContext?.buyerEmail,
      exitDate: vehicleCheckoutContext?.exitDate,
      value: vehicleCheckoutContext?.sellValue,
    } as ISaveOutOfStockDealershipRequest,
    onSubmit: async (values, { setErrors }) => {
      try {
        const credits = await props.creditsToSubmit();
        if (credits) {
          var request = new SaveOutOfStockDealershipRequest(values);
          await saveOutOfStock(request);

          showGenericSuccessModal(null, () => {
            history.push(paths.checkOutDanfe(id));
            setMenuCurrentStepContext(RenaveMenuStepsEnum.CheckOutDanfe);
          });
        }
      } catch (error: any) {
        handleFormikException(
          error?.message,
          setErrors,
          t("ErrorMsgOutOfStock")
        );
      } finally {
        setVehicleCheckoutContext({
          id: values.id,
          buyerDocument: values.businessNumber,
          buyerEmail: values.email,
          exitDate: values.exitDate,
          sellValue: values.value,
          docType: TypeDocumentEnum.Cnpj,
          checkOutType: CheckoutTypeEnum.TransferAuthorization,
        });
      }
    },
    validationSchema: CheckOutDealershipFormMoleculeValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    formik.setValues({ ...formik.values, id: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (props.goSubmit) formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goSubmit]);

  useEffect(() => {
    props.setIsSubmitting(formik.isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Row>
        <FormikControlAtom
          translate={t}
          mask={MaskHelper.Cnpj}
          formik={formik}
          property={"businessNumber"}
          label={t("BusinessNumber")}
          md={6}
          disabled={props.viewMode}
        />
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"email"}
          label={t("Email")}
          md={6}
          disabled={props.viewMode}
        />
      </Form.Row>
      <Form.Row>
        <FormikControlAtom
          type={"money"}
          formik={formik}
          label={t("SellValue")}
          property="value"
          translate={t}
          placeholder="R$"
          md={4}
          disabled={props.viewMode}
        />
        <FormikControlAtom
          translate={t}
          formik={formik}
          property={"exitDate"}
          mask={MaskHelper.Date}
          label={t("ExitDate")}
          md={3}
          disabled={props.viewMode}
        />
      </Form.Row>
      <button type="submit" className="hide"></button>
    </Form>
  );
};
